<template>
  <v-app id="app">
    <AppHeader v-if="isLoaded && isShowHeader" :header="header" @logout="overlay = true" />
    <v-overlay :value="overlay"></v-overlay>
    <v-main>
      <v-container class="fill-height d-flex justify-center" v-if="!isLoaded" fluid>
        <v-progress-circular :size="100" :width="10" indeterminate color="primary"></v-progress-circular>
      </v-container>
      <keep-alive>
        <router-view @created="isLoaded = true" @hide="isShowHeader = false" />
      </keep-alive>
    </v-main>
    <v-footer id="app" v-if="!isMobile || !isLoaded" class="text-body-2">
      <span>ビルド: {{ version }}</span>
      <v-spacer />
      <span>現在時刻: {{ time }}</span>
    </v-footer>
    <AppBottomNavi v-if="isMobile && isLoaded && isShowHeader" :header="header" @logout="overlay = true" />
  </v-app>
</template>

<script>
export default {
  data: () => ({
    isLoaded: false,
    isShowHeader: true,
    isMobile: false,
    overlay: false,
    version: process.env.VUE_APP_BUILD_VERSION,
    header: {
      title: "生産日報",
      icon: "mdi-file-check-outline",
      paths: [
        { text: "点検票", icon: "mdi-list-status", value: "/check-sheet", authority: "general" },
        { text: "確認", icon: "mdi-check-outline", value: "/check-summary", authority: "general" },
        { text: "設定", icon: "mdi-cog-outline", value: "/settings", authority: "confirmer" },
      ],
    },
    time: "",
  }),
  mounted: function () {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    setInterval(this.updateTime, 1000);
  },
  methods: {
    onResize() {
      this.isMobile = document.documentElement.clientWidth < 600;

      // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
      const vh = window.innerHeight * 0.01;
      // カスタム変数--vhの値をドキュメントのルートに設定
      document.documentElement.style.setProperty("--vh", vh + "px");
    },

    updateTime() {
      const hour = ("00" + new Date().getHours()).slice(-2);
      const minute = ("00" + new Date().getMinutes()).slice(-2);
      this.time = `${hour}:${minute}`;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  touch-action: manipulation;
}

.v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
</style>
