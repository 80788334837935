<template>
  <v-menu left nudge-left="10" nudge-top="10" offset-x min-width="400" max-width="400" max-height="500">
    <template v-slot:activator="{ on, attrs }">
      <span v-if="items.length == 0" class="text-body-1">0</span>
      <span
        v-else
        class="primary--text text-body-1 font-weight-bold text-decoration-underline"
        v-bind="attrs"
        v-on="on"
      >
        {{ items.length }}
      </span>
    </template>
    <v-card class="pa-2" color="#f5f5f5">
      <v-card-title class="pa-4 text-subtitle-1 text-sm-h6">利用帳票</v-card-title>
      <v-card-subtitle>{{ title }}</v-card-subtitle>
      <v-card-text class="px-4">
        <v-chip v-for="item in items" :key="item" small>
          {{ item }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
  },
};
</script>
