<template>
  <v-sheet class="text-center" color="transparent" width="172">
    <v-simple-checkbox :value="value" color="primary" :ripple="false" hide-details @input="$emit('input', $event)" />
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, require: true },
  },
};
</script>

<style scoped>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px;
}
</style>
