<template>
  <v-dialog v-model="dialog" persistent width="400" max-width="500">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">{{ title }}</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="#b71c1c" dark depressed :loading="processing" :disabled="processing" @click="confirmed()">
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    method: Function,
  },
  data: () => ({
    dialog: false,
    uid: "",
    title: "",
    text: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開き、選択された行のuid
     * @param {string} uid
     * @param {string} title
     * @param {string} text
     */
    async open(uid, title, text) {
      this.uid = uid;
      this.title = title;
      this.text = text;
      this.dialog = true;
    },

    /**
     * 削除処理
     */
    async confirmed() {
      this.processing = true;
      await this.method("delete", this.uid);
      this.processing = false;
      this.dialog = false;
    },
  },
};
</script>
