<template>
  <v-sheet color="transparent" width="136">
    <v-btn-toggle :value="value" :mandatory="!!value" dense background-color="grey lighten-4" @change="clickButton">
      <v-btn
        :class="value == 'OK' ? '' : 'text--secondary'"
        :color="value == 'OK' ? 'primary' : ''"
        value="OK"
        small
        text
      >
        OK
      </v-btn>
      <v-btn :class="value == 'NG' ? '' : 'text--secondary'" :color="value == 'NG' ? 'red' : ''" value="NG" small text>
        NG
      </v-btn>
    </v-btn-toggle>
    <v-btn v-if="clearable" class="ml-4" icon x-small @click="clickButton('')">
      <v-icon>mdi-eraser</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  props: {
    clearable: Boolean,
    value: String,
  },
  methods: {
    clickButton(text) {
      this.$emit("input", text);
    },
  },
};
</script>

<style scoped>
.btn-result-ok {
  border: 1px solid !important;
  border-color: rgba(220, 220, 220) !important;
  border-radius: 0.25rem 0 0 0.25rem;
}
.btn-result-ng {
  border: 1px solid;
  border-left: 0;
  border-color: rgba(220, 220, 220) !important;
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
