<template>
  <div>
    <!-- 説明 -->
    <p class="text-body-2 text-sm-body-1">{{ type.explain }}</p>

    <!-- 必須 -->
    <v-checkbox
      v-if="'required' in type && showType == 'check'"
      class="mb-2"
      v-model="type.required"
      label="必須入力にする"
      hide-details
      dense
    />

    <!-- 複数選択 -->
    <v-checkbox
      v-if="'multiple' in type"
      class="mb-2"
      v-model="type.multiple"
      label="複数選択する"
      hide-details
      dense
    />

    <!-- 入力ヒント -->
    <v-text-field v-if="'placeholder' in type" class="text-body-2" v-model="type.placeholder" label="入力ヒント" />

    <!-- 表示のみテキスト -->
    <template v-if="type.inputType == 'show'">
      <v-textarea
        class="text-body-2"
        v-model="type.content"
        label="表示するテキストを入力（改行可能）"
        rows="1"
        auto-grow
      />
    </template>

    <!-- 選択肢 -->
    <template v-if="'items' in type">
      <v-text-field
        v-model="type.addText"
        counter="20"
        label="選択肢を追加"
        append-icon="mdi-plus"
        :error-messages="addTextError"
        @keydown.enter="addItem(type.items, type.addText)"
        @click:append="addItem(type.items, type.addText)"
      />
      <p class="mt-2 mb-1 text-body-2">選択肢</p>
      <v-chip v-for="(e, i) in type.items" :key="`chip_${i}`" close small @click:close="type.items.splice(i, 1)">
        {{ e }}
      </v-chip>
    </template>

    <!-- 数値入力設定 -->
    <template v-if="type.inputType == 'number'">
      <div class="mt-n1 input-digit d-flex">
        <v-text-field v-model="type.unit" label="単位" placeholder="例：kg" />
        <v-select v-model="type.digit" :items="[1, 2, 3, 4, 5]" label="整数の最大桁数" />
        <v-select v-model="type.decimalDigit" :items="[1, 2, 3]" label="小数の最大桁数" />
      </div>
      <div class="mb-2 input-allowable d-flex">
        <InputNumber showType="edit" :value="type.min" label="下限" :unit="type.unit" @input="type.min = $event" />
        <InputNumber showType="edit" :value="type.max" label="上限" :unit="type.unit" @input="type.max = $event" />
      </div>
      <p v-if="type.min != '' && type.max != '' && type.min > type.max" class="text-caption red--text">
        下限≦上限で設定してください
      </p>
    </template>

    <!-- 時間入力設定 -->
    <template v-if="type.inputType == 'time'">
      <v-checkbox v-model="type.currentTime" label="初期表示を現在の時刻にする" hide-details dense />
      <p class="mt-6 mb-2 text--secondary text-body-2">表示形式</p>
      <v-radio-group class="ma-0 pa-0" v-model="type.timeFormat" mandatory row hide-details dense>
        <v-radio label="時分秒" value="HH:mm:ss" />
        <v-radio label="時分" value="HH:mm" />
        <v-radio label="分秒" value="mm:ss" />
      </v-radio-group>
    </template>
  </div>
</template>

<script>
import InputNumber from "../molecules/InputNumber";

export default {
  components: {
    InputNumber,
  },
  props: {
    showType: String,
    type: Object,
  },
  data: () => ({
    addTextError: "",
  }),
  methods: {
    /**
     * 入力タイプ「選択」の時、選択肢に追加
     * @param {Array} items
     * @param {value} value
     */
    addItem(items, value) {
      this.addTextError = "";
      if (!value) this.addTextError = "入力してください";
      if (value.length > 20) this.addTextError = "20文字以内で入力してください";
      if (items.includes(value)) this.addTextError = "すでに登録されています";
      if (this.addTextError == "") items.push(value);
    },
  },
};
</script>

<style scoped>
::v-deep .input-digit .v-input:nth-child(-n + 2) {
  margin-right: 8px;
}

::v-deep .input-allowable .div-number:nth-child(1) {
  margin-right: 8px !important;
}
</style>
