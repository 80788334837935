<template>
  <div class="py-2 d-flex">
    <ScrollPicker
      v-if="timeFormat.includes('HH')"
      class="picker text-body-1 tertiary"
      v-model="hour"
      :options="new Array(24).fill().map((_, i) => i.toString())"
      @input="input()"
      style="height: 4em; width: 48px"
      :style="{
        '--color': this.$vuetify.theme.themes.light.primary,
      }"
    />
    <v-sheet v-if="timeFormat.includes('HH:mm')" class="mt-4 d-flex align-center" height="32" color="tertiary">
      <span class="primary-text">:</span>
    </v-sheet>

    <ScrollPicker
      v-if="timeFormat.includes('mm')"
      class="picker text-body-1 tertiary"
      v-model="minute"
      :options="new Array(60).fill().map((_, i) => ('00' + i.toString()).slice(-2))"
      @input="input()"
      style="height: 4em; width: 48px"
      :style="{
        '--color': this.$vuetify.theme.themes.light.primary,
      }"
    />
    <v-sheet v-if="timeFormat.includes('mm:ss')" class="mt-4 d-flex align-center" height="32" color="tertiary">
      <span class="primary-text">:</span>
    </v-sheet>

    <ScrollPicker
      v-if="timeFormat.includes('ss')"
      class="picker text-body-1 tertiary"
      v-model="second"
      :options="new Array(60).fill().map((_, i) => ('00' + i.toString()).slice(-2))"
      @input="input()"
      style="height: 4em; width: 48px"
      :style="{
        '--color': this.$vuetify.theme.themes.light.primary,
      }"
    />
  </div>
</template>

<script>
import "vue-scroll-picker/dist/style.css";
import { ScrollPicker } from "vue-scroll-picker";

export default {
  components: {
    ScrollPicker,
  },
  props: {
    value: String,
    currentTime: Boolean,
    timeFormat: String,
  },
  data: () => ({
    hour: "0",
    minute: "00",
    second: "00",
  }),
  created: function () {
    const currentHour = new Date().getHours().toString();
    const currentMinute = ("00" + new Date().getMinutes()).slice(-2);
    const currentSeocnd = ("00" + new Date().getSeconds()).slice(-2);

    this.hour = this.currentTime ? currentHour : this.value ? this.getTime("hour") : "0";
    this.minute = this.currentTime ? currentMinute : this.value ? this.getTime("minute") : "00";
    this.second = this.currentTime ? currentSeocnd : this.value ? this.getTime("second") : "00";
    this.input();
  },
  computed: {
    styles() {
      return {
        "--color": this.$vuetify.theme.themes.light.primary,
      };
    },
  },
  methods: {
    getTime(time) {
      if (this.timeFormat == "HH:mm:ss") {
        if (time == "hour") return this.value.split(":")[0];
        if (time == "minute") return this.value.split(":")[1];
        if (time == "second") return this.value.split(":")[2];
      }
      if (this.timeFormat == "HH:mm") {
        if (time == "hour") return this.value.split(":")[0];
        if (time == "minute") return this.value.split(":")[1];
      }
      if (this.timeFormat == "mm:ss") {
        if (time == "minute") return this.value.split(":")[0];
        if (time == "second") return this.value.split(":")[1];
      }
    },
    /**
     * ドラムロールが動いたとき
     */
    move(text, time) {
      if (time == "hour") this.hour = text;
      if (time == "minute") this.minute = text;
      if (time == "second") this.second = text;

      this.input();
    },

    /**
     * 入力結果を返す
     */
    input() {
      let timeText = "";
      if (this.timeFormat == "HH:mm:ss") timeText = `${this.hour}:${this.minute}:${this.second}`;
      if (this.timeFormat == "HH:mm") timeText = `${this.hour}:${this.minute}`;
      if (this.timeFormat == "mm:ss") timeText = `${this.minute}:${this.second}`;

      this.$emit("input", timeText);
    },
  },
};
</script>

<style scoped>
.picker ::v-deep .vue-scroll-picker-item.-selected {
  color: var(--color);
}
</style>
