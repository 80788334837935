<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="text-subtitle-1 text-sm-h6">{{ item.id ? "点検項目編集" : "点検項目登録" }}</span>
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <!-- 点検内容 -->
      <v-row class="my-4 px-4" no-gutters>
        <v-col cols="12">
          <v-form ref="form">
            <v-text-field v-model="content" :rules="[(v) => !!v || '必須項目です']" dense>
              <template #label>点検内容<span class="asterisk">*</span></template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>

      <!-- 入力タイプの選択 -->
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="mb-4" no-gutters align-content="start">
        <v-col class="mb-1 px-4 d-flex" cols="12">
          <span class="mr-8 text--primary">入力タイプ</span>
          <v-checkbox class="ma-0 pa-0" v-model="referable" label="前回結果を取得する" hide-details dense />
        </v-col>
        <v-col cols="12"><v-divider /></v-col>
        <v-col class="pt-4 d-flex" cols="12" style="height: 250px">
          <div class="pl-4 pr-6 py-2">
            <v-radio-group class="ma-0 pa-0" v-model="type" mandatory hide-details dense>
              <v-radio v-for="(t, i) in types" :key="i" :label="t.label" :value="t" />
            </v-radio-group>
          </div>
          <v-divider vertical />
          <div class="pl-6 pr-4 py-2" style="flex: 1">
            <InputEdit showType="check" :type="type" />
          </div>
        </v-col>
      </v-row>

      <!-- 入力タイプの選択 縦スマホ時 -->
      <v-row v-else class="mt-8 mb-4" no-gutters>
        <v-col class="px-4" cols="12">
          <v-select
            :value="type.label"
            :items="types.map((t) => t.label)"
            dense
            @change="type = types.find((t) => t.label == $event)"
          >
            <template #label>入力タイプ<span class="asterisk">*</span></template>
          </v-select>
          <v-checkbox class="ma-0 mb-4 pa-0" v-model="referable" label="前回結果を取得する" hide-details dense />
        </v-col>
        <v-col class="px-4 py-2" cols="12" style="min-height: 230px">
          <InputEdit showType="tag" :type="type" />
        </v-col>
      </v-row>

      <!-- 表示例 -->
      <v-row no-gutters align-content="start" style="min-height: 150px">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">表示例</p></v-col>
        <v-col class="mb-4" cols="12"><v-divider /></v-col>
        <v-col
          class="px-4 d-flex"
          :class="type.inputType == 'text' ? 'flex-column' : 'align-center'"
          cols="12"
          style="min-height: 38px"
        >
          <span class="text-body-2">{{ content ? content : "点検内容" }}</span>
          <v-spacer></v-spacer>
          <InputResult showType="check" :inputType="type.inputType" :data="type" :value.sync="type.value" />
        </v-col>
        <v-col cols="12"><v-divider /></v-col>
      </v-row>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="item.updatedAt">更新日：{{ formatDate(item.updatedAt, "YYYY/MM/DD") }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="primary" depressed :loading="processing" :disabled="isDisabledRegistButton" @click="registItem()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import { INPUT_TYPES } from "@/constants/inputTypes";
import InputEdit from "../organisms/InputEdit";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    InputEdit,
    InputResult,
  },
  props: {
    settings: Array,
    updateDB: Function,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    dialog: false,
    item: {},
    content: "",
    referable: false,
    type: {},
    types: [],
    processing: false,
  }),
  computed: {
    /**
     * 登録ボタンの活性判定
     * @return {boolean} 判定結果
     */
    isDisabledRegistButton() {
      if (this.processing) return true;
      if (!this.content) return true;
      if (
        this.type.inputType == "number" &&
        this.type.min != "" &&
        this.type.max != "" &&
        this.type.min > this.type.max
      )
        return true;
      return false;
    },
  },
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {item} item
     */
    async open(item) {
      this.item = {};
      this.type = {};

      // 入力タイプ一覧を取得
      const keys = ["okng", "checkbox", "select", "text", "number", "date", "time"];
      this.types = keys.map((key) => JSON.parse(JSON.stringify(INPUT_TYPES.find((t) => t.inputType == key))));
      this.type = this.types[0];

      // 編集の場合
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
        this.content = item.content;
        this.referable = item.referable;
        this.type = this.types.find((t) => t.inputType == item.type);
        for (const key of Object.keys(this.type)) if (item[key] != undefined) this.type[key] = this.item[key];
      }

      this.dialog = true;
      if (!this.item.id) this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 編集内容をDBに登録
     */
    registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;

      const uid = this.item.id ? this.item.id : this.createDocId("productionReportCheckContents");

      const registData = {
        shopUID: shop.shopUID,
        content: this.content,
        referable: this.referable,
        type: this.type.inputType,
        createdAt: this.item.id
          ? firebase.firestore.Timestamp.fromDate(new Date(this.item.createdAt.seconds * 1000))
          : new Date(),
        updatedAt: new Date(),
        updaterName: user.name,
      };

      // 入力タイプ別に設定追加
      const registKeys = INPUT_TYPES.find((t) => t.inputType == this.type.inputType).registKeys;
      for (const key of Object.keys(this.type)) if (registKeys.includes(key)) registData[key] = this.type[key];

      this.updateDB("set", uid, registData);

      this.processing = false;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-radio .v-label {
  color: #000000de;
}
</style>
