<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">生産日報点検票</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        <MenuSheetSelect :sheets="sheets" :results="results" @select="addToList" />
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="mb-2" no-gutters :style="{ 'min-height': `${getSheetHeight}px` }">
      <v-col>
        <div class="pa-4" v-if="checkSheets.length == 0">
          <span class="text-body-2 text--secondary"> 右上の＋ボタンから帳票を追加して点検してください </span>
        </div>
        <v-card class="mb-2" v-for="(sheet, i) in checkSheets" :key="i" color="#f8f8f8">
          <v-card-title>
            <span class="text-subtitle-2 text-sm-subtitle-1">{{ sheet.sheetName }}</span>
            <v-spacer></v-spacer>
            <MenuComment :item="sheet" targetKey="sheetName" />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="py-2">
                <v-btn text @click="checkSheets.splice(i, 1)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  <span class="ml-2">一覧から削除</span>
                </v-btn>
              </v-card>
            </v-menu>
          </v-card-title>

          <!-- タグ部分 -->
          <v-card-text class="px-2">
            <div class="text-body-2" v-for="(tag, tagIndex) in sheet.tags" :key="`${i}_${tagIndex}`">
              <div v-if="tag.type == 'line'" style="padding: 30px 5%">
                <v-divider
                  :style="{
                    'border-color': '#aaa',
                    'border-width': '1px',
                    'border-style': tag.id,
                  }"
                />
              </div>

              <!-- 送信済の場合 -->
              <template v-else-if="sheet.isSent">
                <template v-if="tag.content">
                  <div v-if="tag.type == 'image'" class="px-2 d-flex">
                    <span>{{ tag.label }}：</span>
                    <ImgThumbnail
                      :src="tag.content"
                      size="80"
                      @click="$refs.dialogImg.openDialog({ text: tag.label, url: tag.content })"
                    />
                  </div>
                  <p v-else class="mb-0 mx-2">{{ tag.label }}：{{ tag.content }}</p>
                </template>
              </template>

              <!-- 新規帳票 -->
              <div
                v-else
                :class="selectKey == `${sheet.id}_tag_${tagIndex}` ? 'tertiary' : 'transparent'"
                @click="selectKey = `${sheet.id}_tag_${tagIndex}`"
              >
                <div
                  class="div-tag px-2 d-flex text-body-2 text--primary"
                  :class="tag.type == 'show' ? 'py-2' : tag.type == 'text' ? 'flex-column' : 'align-center'"
                >
                  <span :class="tag.type == 'text' ? 'pt-2 pb-1' : ''">{{ tag.label }}</span>
                  <span v-if="tag.type == 'show'">：</span>
                  <v-spacer v-if="tag.type != 'show' && tag.type != 'text'" />
                  <InputResult
                    showType="tag"
                    :inputType="tag.type"
                    :data="tag"
                    :value.sync="tag.result"
                    @focus="selectKey = `${sheet.id}_tag_${tagIndex}`"
                  />
                </div>
              </div>
            </div>
          </v-card-text>

          <!-- 点検項目 -->
          <v-data-table
            :headers="headers"
            :items="sheet.contents"
            :items-per-page="-1"
            disable-sort
            hide-default-footer
            :mobile-breakpoint="null"
            dense
          >
            <template #[`item`]="{ item, index }">
              <tr :class="rowBackColor(item, index, sheet)" @click="selectKey = `${sheet.id}_content_${index}`">
                <td>
                  <div v-if="item.type == 'line'" style="padding: 30px 5%">
                    <v-divider
                      :style="{
                        'border-color': '#aaa',
                        'border-width': '1px',
                        'border-style': item.id,
                      }"
                    />
                  </div>
                  <div v-else class="d-flex" :class="item.type == 'text' ? 'flex-column' : 'align-center'">
                    <div class="pt-2 pb-1">
                      <span>{{ item.content }}</span>
                      <v-chip v-if="item.required" class="ml-1 px-1" outlined color="required" x-small>必須</v-chip>
                    </div>
                    <v-spacer></v-spacer>
                    <InputResult
                      showType="check"
                      :inputType="item.type"
                      :data="item"
                      :value.sync="item.result"
                      @focus="selectKey = `${sheet.id}_content_${index}`"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>

          <div class="px-4 py-2">
            <span v-if="sheet.existsEmpty" class="required--text text-body-2">未点検項目があります</span>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="生産日報"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :disabled="checkSheets.length == 0 || sending"
        :isNormal="checkSheets.every((sheet) => sheet.contents.every((content) => isNormalResult(content)))"
        @click="sendCheckResult()"
      />
    </div>

    <DialogImg ref="dialogImg" />
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "../../plugins/firebase";
import { db } from "@/plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import { INPUT_TYPES } from "@/constants/inputTypes";
import MenuSheetSelect from "../organisms/MenuSheetSelect";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    MenuSheetSelect,
    InputResult,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    loading: false,
    businessDate: "",
    sheets: [],
    tags: [],
    contents: [],
    checkSheets: [],
    results: [],
    headers: [{ text: "点検項目", value: "content" }],
    selectKey: "",
    existsComment: false,
    sending: false,
    message: "",
    messageText: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.logEvent("app_connect");
    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: function () {
    this.loadSheets();
    this.loadResults();

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * 点検票エリアの高さを取得
     * @return {number} 高さ
     */
    getSheetHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.existsComment ? 186 : 60;
      const height = bp.height - (bp.xs ? 204 : 196) - offset;
      return height <= 300 ? 300 : height;
    },

    /**
     * OK/NG判定
     * @param {object} item
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (item) => {
        if (item.type === "okng" && item.result == "NG") return false;
        if (item.type === "number" && item.result !== "") {
          if (item.min !== "" && item.min > item.result) return false;
          if (item.max !== "" && item.max < item.result) return false;
        }
        return true;
      };
    },

    /**
     * 点検行の色を取得
     * @param {object} item 点検項目情報
     * @param {number} index 行番号
     * @param {object} sheet 帳票情報
     * @return {string} クラス名
     */
    rowBackColor() {
      return (item, index, sheet) => {
        if (item.type == "line") return "white";
        if (sheet.existsEmpty && item.required && item.result === "") return "bg-required";
        if (!this.isNormalResult(item)) return "red lighten-5";
        if (this.selectKey == `${sheet.id}_content_${index}`) return "tertiary";
        return "white";
      };
    },
  },
  methods: {
    /**
     * DBから帳票一覧取得
     */
    async loadSheets() {
      const shop = this.$store.getters.getShop;

      this.sheets = await this.getQueryDoc({
        collection: "productionReportCheckSheets",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "sheetName", directionStr: "asc" }],
      });

      const tags = await this.getQueryDoc({
        collection: "productionReportCheckTags",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      this.tags = tags.map((tag) => ({
        ...tag,
        result: INPUT_TYPES.find((t) => t.inputType == tag.type).value,
      }));

      this.contents = await this.getQueryDoc({
        collection: "productionReportCheckContents",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      this.contents = this.contents.map((content) => ({ ...content, result: content.type == "checkbox" ? false : "" }));
    },

    /**
     * DBから点検結果を取得
     */
    async loadResults() {
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.businessDate + " 00:00:00");
      const endAt = new Date(this.businessDate + " 23:59:59");

      const results = await this.getQueryDoc({
        collection: "productionReportCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });

      // 送信日時を降順でソート
      results.sort((a, b) => {
        const timeA = new Date(a.sentAt.seconds * 1000);
        const timeB = new Date(b.sentAt.seconds * 1000);
        if (timeA > timeB) return -1;
        if (timeA < timeB) return 1;
        return 0;
      });

      // 同じ帳票の点検結果を抽出
      this.results = [];
      for (const result of results) {
        if (!result.tags) continue;
        const tagText = result.tags.map((e) => `${e.uid}_${e.label}_${e.content}`).join();

        // すでに抽出済か
        const isDuplicate = this.results.some((e) => {
          if (result.id == e.id) return false;
          const targetText = e.tags.map((e) => `${e.uid}_${e.label}_${e.content}`).join();
          return result.sheetName == e.sheetName && tagText == targetText;
        });

        // 未抽出の場合、同じ点検結果の数を集計
        if (!isDuplicate) {
          const checkCount = results.filter((e) => {
            const targetText = e.tags.map((e) => `${e.uid}_${e.label}_${e.content}`).join();
            return result.sheetName == e.sheetName && tagText == targetText;
          }).length;

          this.results.push({ ...result, checkCount });
        }
      }
    },

    /**
     * 帳票一覧から点検リストに追加
     * @param {array} items
     * @param {boolean} isSent
     */
    async addToList({ items, isSent }) {
      for (const item of items) {
        if (this.checkSheets.some((sheet) => sheet.id == item.id)) continue;

        const sheetTags = isSent
          ? item.tags.map((e) => ({ ...e, id: e.uid }))
          : item.tagUID
              .filter((uid) => uid.length < 20 || !!this.tags.find((tag) => tag.id == uid))
              .map((uid) =>
                uid.length == 20
                  ? JSON.parse(JSON.stringify(this.tags.find((tag) => tag.id == uid)))
                  : { id: uid, type: "line", label: "", content: "" }
              );

        const sheetContents = isSent
          ? item.contents.map((e) => ({
              ...e,
              id: e.uid,
              result: e.referable ? e.checkResult : e.type == "checkbox" ? false : "",
            }))
          : item.contentUID
              .filter((uid) => uid.length < 20 || !!this.contents.find((content) => content.id == uid))
              .map((uid) =>
                uid.length == 20
                  ? JSON.parse(JSON.stringify(this.contents.find((content) => content.id == uid)))
                  : { id: uid, type: "line", content: "" }
              );

        this.checkSheets.push({ ...item, isSent, tags: sheetTags, contents: sheetContents, result: "" });
      }
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sentAt = firebase.firestore.FieldValue.serverTimestamp();
      const date = new Date(this.businessDate + " 00:00:00");
      const ngItems = [];
      const sentItems = [];
      const errorItems = [];

      const commonData = {
        userUID: user.userUID,
        shopUID: shop.shopUID,
        registeredAt: date,
        sentAt: sentAt,
        sender: user.name,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        createdAt: sentAt,
        updatedAt: sentAt,
      };

      // 点検結果をループ
      for (const sheet of this.checkSheets) {
        // 必須入力バリデーション
        this.$set(sheet, "existsEmpty", false);
        if (sheet.contents.some((e) => e.result === "" && e.required)) {
          this.$set(sheet, "existsEmpty", true);
          continue;
        }

        // 新規登録用のUIDを事前に取得
        const id = this.createDocId("productionReportCheckResults");

        const tags = await Promise.all(
          sheet.tags.map(async (e, i) => {
            let content = e.content ?? e.result;
            if (!sheet.isSent) {
              if (e.type == "chipSelect") content = e.result.join();
              if (e.type == "number" && e.result !== "") content = `${Number(e.result)}${e.unit ? e.unit : ""}`;
              if (e.type == "image") {
                const path = `productionReportCheckFile/${shop.shopUID}/${id}/${i}.jpg`;
                content = await this.uploadFile(path, e.result.compressedFile);
              }
            }
            return { uid: sheet.isSent ? e.uid : e.id, label: e.label, type: e.type, content };
          })
        );

        const contents = sheet.contents.map((e) => {
          const content = {
            uid: e.id,
            content: e.content,
            type: e.type,
            referable: !!e.referable,
            checkResult: e.result ?? "",
          };
          if (e.required != undefined) content.required = e.required;
          if (e.items != undefined) content.items = e.items;
          if (e.type == "number") {
            content.checkResult = e.result !== "" ? Number(e.result) : "";
            content.unit = e.unit ? e.unit : "";
            content.digit = e.digit ? e.digit : 5;
            content.min = e.min ? e.min : "";
            content.max = e.max ? e.max : "";
            content.decimalDigit = e.decimalDigit ? e.decimalDigit : 2;
          }
          if (e.type == "time") {
            content.currentTime = !!e.currentTime;
            content.timeFormat = e.timeFormat;
          }
          return content;
        });

        const sendData = {
          ...commonData,
          sheetName: sheet.sheetName,
          isNormalForReport: sheet.contents.every((content) => this.isNormalResult(content)),
          tags,
          contents,
        };

        // DB登録
        const result = await this.setDoc("productionReportCheckResults", id, sendData);

        // 特記事項がある場合
        if (result.status == "success" && (sheet.comment || sheet.imgFileUrl)) {
          const storeCommentItem = {
            name: user.name,
            registrantUID: user.userUID,
            shopUID: shop.shopUID,
            position: user.position,
            content: sheet.comment ? sheet.comment : "",
            serviceName: "生産日報",
            relationCheckResult: {
              uid: id,
              title: sendData.sheetName,
              isNormal: sendData.isNormalForReport,
              sentAt: moment().format("MM/DD HH:mm"),
            },
            registeredAt: firebase.firestore.Timestamp.fromDate(date),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          };

          // 新規登録用のUIDを事前に取得
          const commentId = db.collection("comments").doc().id;

          // 画像ありの場合、画像をアップロードする
          if (sheet.compressedFile) {
            const commentDate = this.businessDate.replace(/\//g, "-");
            const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;
            storeCommentItem.imgFileURL = await this.uploadFile(path, sheet.compressedFile);
          }

          await this.setDoc("comments", commentId, storeCommentItem);
        }

        // 送信エラーの場合、エラーリストに追加
        if (result.status == "error") errorItems.push(sendData);

        // NGリストに追加
        if (result.status == "success" && !sendData.isNormalForReport) ngItems.push(sendData);

        sentItems.push(sheet.id);
      }

      this.sending = false;

      if (sentItems.length == 0) return;

      this.message =
        errorItems.length == 0
          ? "送信しました。"
          : "点検票の送信に失敗しました。\nお手数ですが、もう一度お試しください。";
      this.messageText = errorItems.length == 0 ? `点検数：${sentItems.length}` : "";
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message + this.messageText });

      // 点検結果の更新
      if (this.checkSheets.some((e) => e.comment != "")) this.$refs.comments.loadComment();
      this.checkSheets = this.checkSheets.filter((sheet) => !sentItems.includes(sheet.id));
      this.loadResults();

      // NG点検があった場合、メール送信
      if (ngItems.length > 0) this.sendErrorMail(ngItems);
    },

    /**
     * 設定温度を上回る製品があった場合、メール送信
     * @param {array} items NG点検結果
     */
    async sendErrorMail(items) {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      let listText = "";
      for (const item of items) listText = `${listText}帳票名：${item.sheetName}\n`;

      let environment = "";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 生産日報NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 生産日報NGのお知らせ`;

      const mailResult = await sendMail({
        position: user.position,
        shopUID: shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "生産日報の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "" +
          `${shop.selectShopName}\n\n` +
          "【点検結果】\n" +
          `${listText}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        this.logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },
  },
};
</script>

<style scoped>
.div-tag {
  width: 100%;
  min-height: 34px;
  /* border-bottom: solid 1px #0000001f; */
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 38px;
  background-color: #f8f8f8;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  height: 38px;
}

::v-deep .v-data-table__wrapper table tbody tr:last-child td {
  border-bottom: solid 1px #0000001f;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  border-bottom: none !important;
}

.input-number ::v-deep .v-select__slot input {
  text-align: right;
}
</style>
