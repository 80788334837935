<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :left="showType == 'check' || showType == 'tag'"
    offset-x
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click="$emit('focus')">
        <v-text-field
          :clearable="!required"
          :value="value"
          :rules="[required ? (v) => !!v : true]"
          validate-on-blur
          readonly
          hide-details
          dense
          height="32"
          style="max-width: 172px"
          @click:clear="$emit('input', '')"
        >
          <template #append>
            <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
          </template>
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      no-title
      color="primary"
      locale="ja-jp"
      :day-format="(date) => new Date(date).getDate()"
      :value="value.replaceAll(/\//g, '-')"
      @input="
        $emit('input', $event.replaceAll('-', '/'));
        menu = false;
      "
    >
      <div class="text-center" style="width: 100%">
        <v-btn
          class="pa-0"
          text
          color="primary"
          @click="
            $emit('input', today);
            menu = false;
          "
        >
          今日
        </v-btn>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    showType: String,
    value: String,
    required: Boolean,
  },
  data: () => ({
    menu: false,
    today: "",
  }),
  created() {
    this.today = moment().format("YYYY/MM/DD");
  },
};
</script>

<style scoped>
::v-deep .v-input__append-inner {
  margin: auto !important;
}
</style>
