var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pa-sm-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"60px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('span',{staticClass:"mr-5 text-subtitle-1 text-sm-h6"},[_vm._v("生産日報点検票")]),_c('span',{staticClass:"text-caption text-sm-subtitle-1 text--secondary"},[_vm._v(_vm._s(_vm.businessDate))])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[_c('MenuSheetSelect',{attrs:{"sheets":_vm.sheets,"results":_vm.results},on:{"select":_vm.addToList}})],1)],1),_c('v-row',{staticClass:"mb-2",style:({ 'min-height': (_vm.getSheetHeight + "px") }),attrs:{"no-gutters":""}},[_c('v-col',[(_vm.checkSheets.length == 0)?_c('div',{staticClass:"pa-4"},[_c('span',{staticClass:"text-body-2 text--secondary"},[_vm._v(" 右上の＋ボタンから帳票を追加して点検してください ")])]):_vm._e(),_vm._l((_vm.checkSheets),function(sheet,i){return _c('v-card',{key:i,staticClass:"mb-2",attrs:{"color":"#f8f8f8"}},[_c('v-card-title',[_c('span',{staticClass:"text-subtitle-2 text-sm-subtitle-1"},[_vm._v(_vm._s(sheet.sheetName))]),_c('v-spacer'),_c('MenuComment',{attrs:{"item":sheet,"targetKey":"sheetName"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',{staticClass:"py-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.checkSheets.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("一覧から削除")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-2"},_vm._l((sheet.tags),function(tag,tagIndex){return _c('div',{key:(i + "_" + tagIndex),staticClass:"text-body-2"},[(tag.type == 'line')?_c('div',{staticStyle:{"padding":"30px 5%"}},[_c('v-divider',{style:({
                  'border-color': '#aaa',
                  'border-width': '1px',
                  'border-style': tag.id,
                })})],1):(sheet.isSent)?[(tag.content)?[(tag.type == 'image')?_c('div',{staticClass:"px-2 d-flex"},[_c('span',[_vm._v(_vm._s(tag.label)+"：")]),_c('ImgThumbnail',{attrs:{"src":tag.content,"size":"80"},on:{"click":function($event){return _vm.$refs.dialogImg.openDialog({ text: tag.label, url: tag.content })}}})],1):_c('p',{staticClass:"mb-0 mx-2"},[_vm._v(_vm._s(tag.label)+"："+_vm._s(tag.content))])]:_vm._e()]:_c('div',{class:_vm.selectKey == ((sheet.id) + "_tag_" + tagIndex) ? 'tertiary' : 'transparent',on:{"click":function($event){_vm.selectKey = (sheet.id) + "_tag_" + tagIndex}}},[_c('div',{staticClass:"div-tag px-2 d-flex text-body-2 text--primary",class:tag.type == 'show' ? 'py-2' : tag.type == 'text' ? 'flex-column' : 'align-center'},[_c('span',{class:tag.type == 'text' ? 'pt-2 pb-1' : ''},[_vm._v(_vm._s(tag.label))]),(tag.type == 'show')?_c('span',[_vm._v("：")]):_vm._e(),(tag.type != 'show' && tag.type != 'text')?_c('v-spacer'):_vm._e(),_c('InputResult',{attrs:{"showType":"tag","inputType":tag.type,"data":tag,"value":tag.result},on:{"update:value":function($event){return _vm.$set(tag, "result", $event)},"focus":function($event){_vm.selectKey = (sheet.id) + "_tag_" + tagIndex}}})],1)])],2)}),0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":sheet.contents,"items-per-page":-1,"disable-sort":"","hide-default-footer":"","mobile-breakpoint":null,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_c('tr',{class:_vm.rowBackColor(item, index, sheet),on:{"click":function($event){_vm.selectKey = (sheet.id) + "_content_" + index}}},[_c('td',[(item.type == 'line')?_c('div',{staticStyle:{"padding":"30px 5%"}},[_c('v-divider',{style:({
                      'border-color': '#aaa',
                      'border-width': '1px',
                      'border-style': item.id,
                    })})],1):_c('div',{staticClass:"d-flex",class:item.type == 'text' ? 'flex-column' : 'align-center'},[_c('div',{staticClass:"pt-2 pb-1"},[_c('span',[_vm._v(_vm._s(item.content))]),(item.required)?_c('v-chip',{staticClass:"ml-1 px-1",attrs:{"outlined":"","color":"required","x-small":""}},[_vm._v("必須")]):_vm._e()],1),_c('v-spacer'),_c('InputResult',{attrs:{"showType":"check","inputType":item.type,"data":item,"value":item.result},on:{"update:value":function($event){return _vm.$set(item, "result", $event)},"focus":function($event){_vm.selectKey = (sheet.id) + "_content_" + index}}})],1)])])]}}],null,true)}),_c('div',{staticClass:"px-4 py-2"},[(sheet.existsEmpty)?_c('span',{staticClass:"required--text text-body-2"},[_vm._v("未点検項目があります")]):_vm._e()])],1)})],2)],1),_c('div',{staticClass:"d-sm-flex align-center"},[_c('CardComments',{ref:"comments",attrs:{"date":_vm.businessDate,"isMonthly":false,"serviceName":"生産日報"},on:{"load":function($event){_vm.existsComment = $event.length > 0}}}),_c('ButtonSendResult',{attrs:{"loading":_vm.sending,"disabled":_vm.checkSheets.length == 0 || _vm.sending,"isNormal":_vm.checkSheets.every(function (sheet) { return sheet.contents.every(function (content) { return _vm.isNormalResult(content); }); })},on:{"click":function($event){return _vm.sendCheckResult()}}})],1),_c('DialogImg',{ref:"dialogImg"}),_c('DialogMessage',{attrs:{"dialog":_vm.messageDialog,"message":_vm.message,"messageText":_vm.messageText},on:{"close":function($event){_vm.messageDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }