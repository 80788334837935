<template>
  <div>
    <!-- 画像未選択時 -->
    <v-btn v-if="!url" icon @click.stop="$refs.fileInput.click()">
      <v-icon>mdi-image-plus</v-icon>
    </v-btn>

    <!-- 画像選択後 -->
    <div v-if="url" class="d-flex align-start" :class="readonly ? '' : 'py-3'">
      <v-img
        class="rounded-lg"
        :src="url"
        :width="width"
        aspect-ratio="1"
        @click.stop="$refs.dialogImg.openDialog({ text: text, url: url })"
        style="border: solid 1px #0000001f"
      />
      <v-icon
        v-if="!readonly"
        class="ml-n3 mt-n3 white grey--text text--darken-2"
        style="border-radius: 50%"
        @click.stop="cancelImg()"
      >
        mdi-close-circle
      </v-icon>
    </div>

    <!-- ファイルインプットタグ -->
    <input class="d-none" ref="fileInput" type="file" accept="image/jpeg, image/jpg, image/png" @input="selectImg" />

    <!-- 画像表示 -->
    <DialogImg ref="dialogImg" />
  </div>
</template>

<script>
import uploadStorage from "cumin-common/src/mixins/uploadStorage";

export default {
  props: {
    text: String,
    url: String,
    compressedFile: Blob,
    width: { type: [String, Number], default: "58" },
    readonly: Boolean,
  },
  mixins: [uploadStorage],
  methods: {
    /**
     * 画像ファイルの選択
     * @param {Event} event
     */
    async selectImg(event) {
      const file = event.target.files[0];
      event.target.value = "";

      // 画像を圧縮する
      const data = await this.selectFile(file);

      this.$emit("input", { url: data.url, compressedFile: data.compressedFile });
    },

    /**
     * 画像ファイルの削除
     */
    async cancelImg() {
      this.$emit("input", { url: "", compressedFile: null });
    },
  },
};
</script>
