<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">帳票設定</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <!-- タブ部分 -->
        <v-tabs v-model="tab" show-arrows background-color="#f5f5f5">
          <v-tab v-for="(setting, i) in settings" :key="i">
            <v-icon class="mr-1" small>{{ setting.icon }}</v-icon>
            {{ setting.name }}
          </v-tab>
        </v-tabs>

        <!-- タブの内容 -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(setting, i) in settings" :key="`tab_${i}`">
            <v-card :height="getCardHeight" color="#fafafa">
              <v-card-title class="d-flex text-body-1">
                {{ `${settings[tab].name}一覧` }}
                <v-spacer></v-spacer>
                <v-btn class="white" color="primary" icon elevation="1" @click="$refs[settings[tab].ref].open()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-data-table
                :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
                :headers="setting.headers"
                :items="setting.items"
                :items-per-page="-1"
                :loading="loading"
                loading-text="読込中"
                no-data-text="データがありません"
                :disable-sort="$vuetify.breakpoint.xs"
                fixed-header
                hide-default-footer
                :height="getCardHeight - 68"
                dense
              >
                <template v-slot:[`item.tagCount`]="{ item }">{{ item.tagUID.length }}</template>
                <template v-slot:[`item.contentCount`]="{ item }">{{ item.contentUID.length }}</template>
                <template v-slot:[`item.allowableMin`]="{ item }">{{ item.allowableMin }}℃</template>
                <template v-slot:[`item.allowableMax`]="{ item }">{{ item.allowableMax }}℃</template>
                <template v-slot:[`item.referable`]="{ item }">
                  <v-icon v-if="item.referable">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.required`]="{ item }">
                  <v-icon v-if="item.required">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <span>{{ inputType[item.type] }}</span>
                  <span class="ml-2 text-caption text--secondary" v-if="item.type == 'number'"
                    >単位：{{ item.unit ? item.unit : "なし" }}
                  </span>
                  <span class="text-caption text--secondary" v-if="item.type == 'time'">
                    <br />形式：{{ item.timeFormat }}
                  </span>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <span class="text-caption">{{ formatDate(item.updatedAt, "YYYY/MM/DD HH:mm") }}</span>
                </template>
                <template #[`item.useSheetsCount`]="{ item }">
                  <MenuUseSheets
                    :title="`${setting.headers[0].text}：${item[setting.headers[0].value]}`"
                    :items="item.useSheets"
                  />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn icon @click.stop="$refs[setting.ref].open(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="
                      $refs.confirmDialog.open(
                        item.id,
                        `下記${setting.name}を削除します`,
                        `${setting.name}名：${item[setting.order]}`
                      )
                    "
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <DialogEditSheet ref="editSheetDialog" :settings="settings" :updateDB="updateDB" />
    <DialogEditTag ref="editTagDialog" :items="settings.find((s) => s.name == 'タグ').items" :updateDB="updateDB" />
    <DialogEditContent
      ref="editContentDialog"
      :items="settings.find((s) => s.name == '点検項目').items"
      :updateDB="updateDB"
    />
    <DialogConfirm ref="confirmDialog" :method="updateDB" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogEditSheet from "../dialogs/DialogEditSheet";
import DialogEditTag from "../dialogs/DialogEditTag";
import DialogEditContent from "../dialogs/DialogEditContent";
import DialogConfirm from "../dialogs/DialogConfirm";
import MenuUseSheets from "../organisms/MenuUseSheets";

export default {
  components: {
    DialogEditSheet,
    DialogEditTag,
    DialogEditContent,
    DialogConfirm,
    MenuUseSheets,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    tab: 0,
    settings: [
      {
        name: "帳票",
        icon: "mdi-text-box-outline",
        collection: "productionReportCheckSheets",
        order: "sheetName",
        ref: "editSheetDialog",
        headers: [
          { text: "帳票名", value: "sheetName" },
          { text: "タグ数", value: "tagCount", align: "end", width: 110 },
          { text: "点検項目数", value: "contentCount", align: "end", width: 110 },
          { text: "更新日時", value: "updatedAt", width: 140 },
        ],
        items: [],
      },
      {
        name: "タグ",
        icon: "mdi-tag-outline",
        collection: "productionReportCheckTags",
        order: "tagName",
        ref: "editTagDialog",
        headers: [
          { text: "タグ名", value: "tagName" },
          { text: "ラベル名", value: "label" },
          { text: "入力タイプ", value: "type", width: 160 },
          { text: "利用帳票数", value: "useSheetsCount", align: "end", width: 112 },
          { text: "更新日時", value: "updatedAt", width: 140 },
        ],
        items: [],
      },
      {
        name: "点検項目",
        icon: "mdi-format-list-numbered",
        collection: "productionReportCheckContents",
        order: "content",
        ref: "editContentDialog",
        headers: [
          { text: "点検内容", value: "content" },
          { text: "前回結果を取得", value: "referable", width: 90 },
          { text: "必須", value: "required", width: 76 },
          { text: "入力タイプ", value: "type", width: 160 },
          { text: "利用帳票数", value: "useSheetsCount", align: "end", width: 112 },
          { text: "更新日時", value: "updatedAt", width: 140 },
        ],
        items: [],
      },
    ],
    inputType: {
      show: "表示のみ",
      checkbox: "チェックボックス",
      select: "プルダウンで選択",
      chipSelect: "一覧から選択",
      text: "テキスト入力",
      number: "数値入力",
      date: "日付入力",
      time: "時間入力",
      okng: "OK / NG",
      image: "画像添付",
    },
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    for (const setting of this.settings)
      setting.headers.push({ text: "", value: "action", sortable: false, width: "104px" });
  },
  activated: function () {
    this.loadSetting();
  },
  computed: {
    /**
     * データテーブルの高さ取得
     * @return {number} 高さ
     */
    getCardHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 234 : 226);
      return height <= 500 ? 500 : height;
    },
  },
  methods: {
    /**
     * DBから設定情報取得
     */
    async loadSetting(tab) {
      const shop = this.$store.getters.getShop;
      this.loading = true;

      const getData = async (setting) => {
        setting.items = await this.getQueryDoc({
          collection: setting.collection,
          where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
          order: [{ fieldPath: setting.order, directionStr: "asc" }],
        });
        if (setting.name == "帳票") return;

        // タグ、点検項目の場合、利用中の帳票をカウント
        const sheets = this.settings[0].items;
        const key = setting.name == "タグ" ? "tagUID" : "contentUID";
        for (const item of setting.items) {
          item.useSheets = sheets.filter((e) => e[key].includes(item.id)).map((e) => e.sheetName);
          item.useSheetsCount = item.useSheets.length;
        }
      };

      if (tab == undefined) for (const setting of this.settings) getData(setting);
      if (tab != undefined) getData(this.settings[tab]);

      this.loading = false;
    },

    /**
     * 変更内容をDBに登録
     * @param {boolean} mode
     * @param {string} uid
     * @param {object} registData 登録情報
     */
    async updateDB(mode, uid, registData) {
      const result =
        mode == "set"
          ? await this.updateDoc(this.settings[this.tab].collection, uid, registData)
          : await this.deleteDoc(this.settings[this.tab].collection, uid);
      const isSuccess = (result.status = "success");

      this.message = isSuccess
        ? `${this.settings[this.tab].name}情報を更新しました。`
        : `${this.settings[this.tab].name}情報の更新に失敗しました。\nお手数ですが、もう一度お試しください。`;
      this.messageDialog = true;

      if (isSuccess) this.loadSetting(this.tab);
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  border-bottom: solid 1px #0000001f !important;
}

.theme--light.v-tabs .v-tab--active:focus::before {
  opacity: 0 !important;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0 !important;
}

.v-window {
  overflow: initial;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 38px;
  background-color: #fafafa;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
}
</style>
