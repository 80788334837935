<template>
  <v-select
    :value="value"
    :items="items"
    :rules="[required ? (v) => !!v : true]"
    :clearable="!required"
    validate-on-blur
    menu-props="auto"
    no-data-text="選択肢がありません"
    hide-details
    dense
    height="32"
    style="max-width: 172px"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: String,
    items: Array,
    required: Boolean,
  },
};
</script>

<style scoped>
::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
}
</style>
