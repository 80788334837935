<template>
  <div class="py-2 d-flex flex-wrap">
    <v-chip
      class="ml-1"
      v-for="(e, i) in items"
      :key="`chip_result_${i}`"
      :color="selected.includes(e) ? 'primary' : ''"
      label
      @click="select(e)"
    >
      {{ e }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    selected: Array,
    items: Array,
    multiple: Boolean,
  },
  methods: {
    select(e) {
      if (this.multiple) {
        const edited = [...this.selected];
        const index = edited.indexOf(e);
        edited.includes(e) ? edited.splice(index, 1) : edited.push(e);
        this.$emit("input", [...edited]);
      } else {
        this.$emit("input", this.selected.includes(e) ? [] : [e]);
      }
    },
  },
};
</script>
