<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="5" sm="4" align-self="center">
        <MenuDatePicker
          :date="displayDate"
          @update="
            displayDate = $event;
            reloadList();
          "
        />
      </v-col>
      <v-col class="text-end" align-self="center" cols="3" sm="4">
        <DialogExportResult serviceName="生産日報" :properties="exportProperties" :selectedDate="displayDate" />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <TableSummary
          :selected.sync="selected"
          :items="items"
          :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
          :filterHeader="filterHeader"
          :comments="comments"
          :loading="loading"
          sortBy="sentAt"
          :sortDesc="true"
          @clickRow="$refs.checkResultDialog.openDialog($event)"
        >
          <template v-slot:[`item.tags`]="{ item }">
            <td class="py-1" style="max-width: 300px">
              <p
                class="grey--text text-caption text-truncate"
                v-for="(tag, i) in item.tags.filter((e) => e.type == 'show' || !!e.content).slice(0, 5)"
                :key="`${tag.id}_${i}`"
              >
                {{ tag.label }}<span v-if="tag.type !== 'image'">：{{ tag.content }}</span>
              </p>
            </td>
          </template>
        </TableSummary>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="displayDate"
        :isMonthly="false"
        serviceName="生産日報"
        @load="comments = $event"
      />
      <ButtonApproveResult
        :items="selected"
        collection="productionReportCheckResults"
        :disabled="selected.length == 0"
        @reload="loadList()"
      />
    </div>

    <DialogCheckResult ref="checkResultDialog" :comments="comments" @update="$refs.comments.loadComment()" />
  </v-container>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogCheckResult from "../dialogs/DialogCheckResult";
import DialogExportResult from "../dialogs/DialogExportResult";

export default {
  components: {
    DialogCheckResult,
    DialogExportResult,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    displayDate: "",
    sheets: [],
    search: "",
    selected: [],
    items: [],
    headers: [
      { text: "帳票名", value: "sheetName", sortable: false, width: 150, class: "responsive" },
      { text: "タグ名", value: "tags", width: 150, class: "responsive" },
      { text: "点検結果", value: "isNormalForReport", width: 116 },
      { text: "送信日時", value: "sentAt", width: 116 },
      { text: "確認日時", value: "confirmedAt", width: 116 },
      { text: "承認日時", value: "approvedAt", width: 116 },
    ],
    xsHeaders: [
      { text: "帳票名", value: "sheetName", sortable: false, width: 150, class: "responsive" },
      { text: "タグ名", value: "tags", width: 150, class: "responsive" },
      { text: "結果", value: "isNormalForReport", width: 58 },
      { text: "送信", value: "sentAt", align: "center", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    filterHeader: { sheetName: "" },
    comments: [],
    exportProperties: [
      {
        sheetName: "生産日報",
        collection: "productionReportCheckResults",
        resultKey: "contents",
        resultColumns: [
          { header: "点検内容", key: "content" },
          { header: "点検結果", key: "checkResult" },
        ],
        columns: [
          { header: "帳票名", key: "sheetName" },
          { header: "タグ", key: "tags" },
          { header: "点検結果", key: "isNormalForReport" },
          { header: "送信日時", key: "sentAt" },
          { header: "送信者", key: "sender" },
          { header: "確認日時", key: "confirmedAt" },
          { header: "確認者", key: "confirmerName" },
          { header: "承認日時", key: "approvedAt" },
          { header: "承認者", key: "approverName" },
        ],
      },
    ],
  }),
  created: function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.displayDate = queryDate ? queryDate : this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    this.loading = true;
    await this.loadData();
    await this.loadList();
    this.loading = false;
  },
  methods: {
    /**
     * DBから製品一覧を取得
     */
    async loadData() {
      const shop = this.$store.getters.getShop;

      this.sheets = await this.getQueryDoc({
        collection: "productionReportCheckSheets",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "sheetName", directionStr: "asc" }],
      });
    },

    /**
     * DBから点検結果を取得
     */
    async loadList() {
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.displayDate + " 00:00:00");
      const endAt = new Date(this.displayDate + " 23:59:59");

      this.items = await this.getQueryDoc({
        collection: "productionReportCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });
    },

    /**
     * 点検項目、点検一覧、コメントの再読み込み
     */
    async reloadList() {
      this.loading = true;
      await this.loadList();
      this.$refs.comments.loadComment();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
</style>
