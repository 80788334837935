<template>
  <div class="div-number">
    <p v-if="isNumber(min) || isNumber(max)" class="mb-n2 text-caption text--secondary">
      許容値：{{ min }} ～ {{ max }}
    </p>
    <v-menu
      v-model="menu"
      :left="showType == 'check' || showType == 'tag'"
      :offset-x="showType != 'edit'"
      :offset-y="showType == 'edit'"
      :close-on-content-click="false"
      @input="$event ? openMenu() : closeMenu()"
      max-width="264"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="$emit('focus')">
          <v-combobox
            class="input-number"
            :value="value"
            :label="label"
            :suffix="unit"
            :rules="[required ? (v) => v !== '' : true]"
            validate-on-blur
            readonly
            hide-details
            dense
            height="32"
            style="max-width: 172px"
          />
        </div>
      </template>
      <v-card elevation="10" color="#eee" style="opacity: 0.95; touch-action: manipulation">
        <v-row no-gutters class="pa-1">
          <v-col class="pt-4 pb-2 pr-2 text-end" cols="12">
            <span class="px-1 text-h3">{{ value }}</span>
          </v-col>
          <v-col cols="9">
            <v-btn
              class="tenkey primary--text"
              v-for="key in keys"
              :key="`tenkey_${key}`"
              height="50"
              :width="key == 0 ? 128 : 64"
              @click="clickKey(key)"
            >
              <span class="text-body-1">{{ key }}</span>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="tenkey primary--text" height="50" @click="removeValue()">
              <v-icon small>mdi-backspace-outline</v-icon>
            </v-btn>
            <v-btn class="tenkey primary--text px-0" height="50" @click="reverseValue()">
              <v-icon>mdi-plus-minus-variant</v-icon>
            </v-btn>
            <v-btn class="tenkey primary--text" height="100" @click="closeMenu()">
              <v-icon small>mdi-keyboard-return</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    showType: String,
    value: [String, Number],
    label: String,
    unit: String,
    digit: Number,
    decimalDigit: Number,
    min: [String, Number],
    max: [String, Number],
    required: Boolean,
  },
  data: () => ({
    menu: false,
    keys: ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0", "."],
    isInitial: false,
  }),
  computed: {
    isNumber() {
      return (value) => typeof value === "number" && !isNaN(value);
    },
  },
  methods: {
    /**
     * メニューが開いたとき
     * @param {object} isOpen 開いたか
     */
    openMenu() {
      this.isInitial = true;
      document.addEventListener("keydown", this.keydown);
    },

    /**
     * メニューを閉じる
     * @param {object} isOpen 開いたか
     */
    closeMenu() {
      this.isInitial = true;
      document.removeEventListener("keydown", this.keydown);
      const isNumber = this.value != "" && !isNaN(Number(this.value));
      this.$emit("input", isNumber ? Number(this.value) : "");
      this.menu = false;
    },

    /**
     * 直接テンキーを入力したとき
     * @param {object} event
     */
    keydown(event) {
      if (!this.menu) return;
      if (this.keys.includes(event.key)) this.clickKey(event.key);
      if (event.key == "-") this.reverseValue();
      if (event.key == "Backspace") this.removeValue();
      if (event.key == "Enter") this.closeMenu();
    },

    /**
     * テンキー入力
     * @param {string} text 入力文字
     */
    clickKey(text) {
      // 初回入力または、0状態の場合はリセットする
      let currentValue = this.value != undefined ? String(this.value) : "";
      if (this.isInitial || currentValue == "0") currentValue = "";
      if (currentValue == "-0") currentValue = "-";

      // 結合する文字列を取得する
      let mergeText = text;

      // 小数点入力の場合
      if (mergeText == ".") {
        if (String(currentValue).includes(".")) return;
        if (currentValue == "" || currentValue == "-") mergeText = "0.";
      }

      // 数値入力の場合
      if (mergeText != ".") {
        const numbers = String(currentValue).split(".");

        // 整数且つ桁数オーバーの場合
        if (numbers[1] == undefined && numbers[0].length > this.digit - 1) currentValue = currentValue.slice(0, -1);

        // 小数点がある且つ桁数オーバーの場合
        if (numbers[1] != undefined && numbers[1].length > this.decimalDigit - 1)
          currentValue = currentValue.slice(0, -1);
      }

      this.$emit("input", currentValue + mergeText);
      this.isInitial = false;
    },

    /**
     * 1文字消す
     */
    removeValue() {
      const currentValue = this.value != undefined ? String(this.value) : "";

      // もともと空文字の場合は終了
      if (currentValue == "") return;

      let editedValue = currentValue.slice(0, -1);

      // 削除後、空文字になる場合は初期化
      if (editedValue == "") editedValue = "";
      this.$emit("input", editedValue);
      this.isInitial = false;
    },

    /**
     * サインチェンジキー
     */
    reverseValue() {
      const currentValue = this.value != undefined ? String(this.value) : "";
      const isMinus = currentValue.includes("-");
      this.$emit("input", isMinus ? currentValue.replace("-", "") : `-${currentValue}`);
      this.isInitial = false;
    },
  },
};
</script>

<style scoped>
.tenkey {
  padding: 0;
  border: 1px solid #eee !important;
  border-radius: 0;
  box-shadow: initial;
}

.input-number ::v-deep .v-select__slot input {
  text-align: right;
}
</style>
