<template>
  <v-sheet class="d-flex" color="transparent" :width="inputType == 'text' ? '100%' : ''">
    <!-- テキスト表示のみ -->
    <div v-if="inputType == 'show'" class="d-flex align-center" style="white-space: pre-wrap">
      <span class="text-body-2">{{ data.content }}</span>
    </div>

    <!-- OK・NG -->
    <template v-if="inputType == 'okng'">
      <InputOkNg :clearable="!required" :value="value" @input="input" />
    </template>

    <!-- チェックボックス -->
    <template v-if="inputType == 'checkbox'">
      <InputCheckBox
        :value="value"
        @input="
          input($event);
          $emit('focus');
        "
      />
    </template>

    <!-- プルダウンで選択 -->
    <template v-if="inputType == 'select'">
      <InputSelect :value="value" :items="items" :required="required" @input="input" />
    </template>

    <!-- チップで選択 -->
    <template v-if="inputType == 'chipSelect'">
      <InputSelectChip :selected="value" :items="items" :multiple="multiple" @input="input" />
    </template>

    <!-- テキスト入力 -->
    <template v-if="inputType == 'text'">
      <InputText :value="value" :placeholder="placeholder" :required="required" @input="input" />
    </template>

    <!-- 数値入力 -->
    <template v-if="inputType == 'number'">
      <InputNumber
        :showType="showType"
        :value="value"
        :unit="unit"
        :digit="digit"
        :decimalDigit="decimalDigit"
        :min="min"
        :max="max"
        :required="required"
        @input="input"
        @focus="$emit('focus')"
      />
    </template>

    <!-- 日付入力 -->
    <template v-if="inputType == 'date'">
      <InputDate :showType="showType" :value="value" :required="required" @input="input" @focus="$emit('focus')" />
    </template>

    <!-- 時間入力 -->
    <template v-if="inputType == 'time'">
      <InputTime :value="value" :currentTime="currentTime" :timeFormat="timeFormat" @input="input" />
    </template>

    <template v-if="inputType == 'image'">
      <InputImg :url="value.url" @input="input" />
    </template>
  </v-sheet>
</template>

<script>
import InputOkNg from "../molecules/InputOkNg";
import InputCheckBox from "../molecules/InputCheckBox";
import InputDate from "../molecules/InputDate";
import InputNumber from "../molecules/InputNumber";
import InputSelect from "../molecules/InputSelect";
import InputSelectChip from "../molecules/InputSelectChip";
import InputText from "../molecules/InputText";
import InputTime from "../molecules/InputTime";
import InputImg from "../molecules/InputImg";

export default {
  props: {
    showType: { type: String, require: true },
    inputType: { type: String, require: true },
    data: { type: Object, require: true },
    value: { type: [String, Number, Array, Boolean, Object], require: true },
  },
  components: {
    InputOkNg,
    InputCheckBox,
    InputDate,
    InputNumber,
    InputSelect,
    InputSelectChip,
    InputText,
    InputTime,
    InputImg,
  },
  computed: {
    required() {
      return this.data.required;
    },
    placeholder() {
      return this.data.placeholder;
    },
    selected() {
      return this.data.selected;
    },
    items() {
      return this.data.items;
    },
    multiple() {
      return this.data.multiple;
    },
    unit() {
      return this.data.unit;
    },
    digit() {
      return this.data.digit;
    },
    decimalDigit() {
      return this.data.decimalDigit;
    },
    min() {
      return this.data.min;
    },
    max() {
      return this.data.max;
    },
    currentTime() {
      return this.data.currentTime;
    },
    timeFormat() {
      return this.data.timeFormat;
    },
  },
  methods: {
    input(event) {
      this.$emit("update:value", event);
    },
  },
};
</script>
