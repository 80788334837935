<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">点検結果詳細</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- 詳細内容 -->
      <CheckResultDetail :result="item">
        <ButtonCreatePDF title="生産日報" subTitle="点検結果詳細" icon="mdi-file-check-outline">
          <CheckResultDetail :result="item" />
          <div class="py-2">
            <p class="mb-1 pl-4 text-caption text-sm-body-2">特記事項</p>
            <ListComments
              :comments="comments.filter((e) => e.relationCheckResult && e.relationCheckResult.uid == item.id)"
              :relationCheckResult="{}"
              :readOnly="true"
            />
          </div>
        </ButtonCreatePDF>
      </CheckResultDetail>

      <!-- 特記事項リスト -->
      <div class="px-4 pt-2 text-caption text-sm-body-2">特記事項</div>
      <ListComments
        :date="formatDate(item.registeredAt, 'YYYY/MM/DD')"
        :comments="comments.filter((e) => e.relationCheckResult && e.relationCheckResult.uid == item.id)"
        :relationCheckResult="{
          uid: item.id,
          title: item.sheetName,
          isNormal: item.isNormalForReport,
          sentAt: formatDate(item.sentAt, 'MM/DD HH:mm'),
        }"
        serviceName="生産日報"
        :height="200"
        @update="$emit('update')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckResultDetail from "../organisms/CheckResultDetail";

export default {
  props: {
    comments: Array,
  },
  components: {
    CheckResultDetail,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    item: {},
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した行データ
     */
    openDialog(selectedItem) {
      this.item = selectedItem;
      this.dialog = true;
    },
  },
};
</script>
