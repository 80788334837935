<template>
  <v-textarea
    :value="value"
    :rules="[required ? (v) => !!v : true]"
    :placeholder="placeholder"
    validate-on-blur
    rows="1"
    auto-grow
    hide-details
    outlined
    dense
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    showType: String,
    value: String,
    placeholder: String,
    required: Boolean,
  },
};
</script>
